.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.language-flag {
  width: 25px;
  height: 25px;
  border-radius: 50%; /* Makes the flag circular */
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.language-flag svg {
  width: 100%; /* Make the image fill the container */
  height: 100%;
  object-fit: cover; /* Ensure the image scales properly without distortion */
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.banner-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 16px;
  overflow: hidden;
}
.banner-image img{
  min-width: 100%;
  min-height: 200px;
}
.page-avatar {
  border-radius: 50%;
  width: 100%;
  max-width: 188px;
  max-height: 188px;
  overflow: hidden;
  border: 2px solid #000;
  object-fit: cover;
}
.page-info {
  position: relative;
  bottom: 70px;
  width: 100%;
}
.analytics-info {
  border-radius: 12px;
  background-color: #23232D;
  padding: 25px 20px;
}
.analytics-info h4 {
  font-size: 16px;
  text-transform: uppercase;
  color: #8A8AA0;
  font-weight: 500;
}

.analytics-info p {
  font-size: 20px;
  font-weight: 600;
  color: #fff;
  margin-top: 10px;
}
.text-life3 {
  color: #00BFFF;
}

.heading-6 {
  font-size: 20px;
  font-weight: 600;
}
.bg-transparent {
  background-color: rgba(255, 255, 255, 0.05);
}

.update-page {
  width: 100%;
  position: absolute;
  min-height: 1020px;
  background: rgba(0, 0, 0, 0.8);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
}

.update-page-content {
  min-height: 200px;
}

@media (min-width: 576px) {
  .update-page {
    min-height: 980px;
  }
}

@media (max-width: 767px) {
  .heading-live-auctions {
      display: block; 
  }
  
  .heading-live-auctions > * {
      margin-bottom: 10px;
  }

  .title-collection h2 {
    margin-bottom: 10px;
  }

  .home-7 .tf-section.today-pick {
    padding: 40px 0 80px;
  }

  .widget-search {
    margin-bottom: 15px;
    margin-top: 5px;
    float: left !important
  }
  .analytics-info .row .mt-2 {
    width: 50%;
    float: left;
  }
  .flat-title-page.style2 .shape.item-w-22,
  .swiper-pagination-bullets,
  .swiper-button-prev,
  .swiper-button-next{
    bottom: -20px !important
  }

  .swiper {
    overflow: visible !important;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
